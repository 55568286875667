<template>
  <div class="manual-checkin">
    <!-- "X" oben rechts -->
    <router-link to="/" class="close-button">✕</router-link>

    <h1>Neue Karte anlernen</h1>

    <!-- Ladezustand -->
    <div v-if="loading" class="loading">
      Daten werden geladen...
    </div>

    <!-- Fehlermeldung -->
    <div v-if="error" class="error">
      {{ error }}
    </div>

    <!-- Liste der Mitarbeiter -->
    <div v-if="!loading && !error && !notificationMessage" class="employee-list">
      <div
          v-for="employee in employees"
          :key="employee.id"
          class="employee-tile"
          @click="selectEmployee(employee)"
      >
        {{ employee.name }}
      </div>
    </div>

    <!-- NFC-Aktivierungs-Kachel -->
    <div v-if="selectedEmployee && !notificationMessage" class="action-tile" ref="actionTile">
      <h2>{{ selectedEmployee.name }}</h2>
      <p>Bitte halt die Karte hinter das Terminal.</p>
      <p v-if="nfcStatus" class="nfc-status">{{ nfcStatus }}</p>
    </div>

    <!-- Benachrichtigungs-Box -->
    <div v-if="notificationMessage" class="notification">
      {{ notificationMessage }}
    </div>
  </div>
</template>

<script>
/* global NDEFReader */
export default {
  data() {
    return {
      employees: [], // Liste der Mitarbeiter
      selectedEmployee: null, // Ausgewählter Mitarbeiter
      loading: true, // Ladezustand
      error: null, // Fehlernachricht
      notificationMessage: null, // Nachricht für die Benachrichtigung
      nfcStatus: null, // Status der NFC-Funktion
      ndef: null, // NFC-Reader Instanz
      isReadingBlocked: false, // Sperrflagge für mehrfaches Einlesen
    };
  },
  methods: {
    // Lade die Liste der Mitarbeiter per AJAX
    async fetchEmployees() {
      try {
        const response = await fetch(`${process.env.VUE_APP_CVCI_API_URL}/employees`);
        if (!response.ok) {
          throw new Error('Fehler beim Laden der Mitarbeiterdaten');
        }
        this.employees = await response.json();
        this.loading = false;
      } catch (err) {
        this.error = err.message;
        this.loading = false;
      }
    },
    // Auswahl eines Mitarbeiters und NFC aktivieren
    selectEmployee(employee) {
      this.selectedEmployee = employee;
      this.startNfcNewCard();

      // Nach unten scrollen, sobald ein Mitarbeiter ausgewählt wurde
      this.$nextTick(() => {
        const actionTile = this.$refs.actionTile;
        if (actionTile) {
          actionTile.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    },
    // NFC-Scan starten
    async startNfcNewCard() {
      try {
        if (!('NDEFReader' in window)) {
          throw new Error('NFC wird von diesem Gerät nicht unterstützt.');
        }

        if (!this.ndefreader) {
          this.ndefreader = new NDEFReader();
          this.nfcStatus = 'NFC wird aktiviert...';

          await this.ndefreader.scan();
        } else {
          // Entferne vorherigen Listener, falls vorhanden
          this.ndefreader.onreading = null;
        }

        this.nfcStatus = 'Bereit zum Scannen.';

        // NFC-Lesefunktion
        this.ndefreader.onreading = async (event) => {
          if (this.isReadingBlocked) {
            this.nfcStatus = 'Warte, Karte wird bereits verarbeitet...';
            return; // Verhindere doppelte Verarbeitung
          }

          this.isReadingBlocked = true; // Sperrung aktivieren
          const serialNumber = event.serialNumber || 'unbekannt';
          this.nfcStatus = `Karte erkannt: Seriennummer ${serialNumber}`;

          await this.sendNfcData(serialNumber);
          this.ndefreader.onreading = null;
          // Sperrung nach 5 Sekunden aufheben
          setTimeout(() => {
            this.isReadingBlocked = false;
          }, 5000);
        };
      } catch (err) {
        this.nfcStatus = `Fehler: ${err.message}`;
      }
    },
    async sendNfcData(serialNumber) {
      try {
        if (!this.selectedEmployee) {
          throw new Error('Kein Mitarbeiter ausgewählt.');
        }

        const response = await fetch(`${process.env.VUE_APP_CVCI_API_URL}/assign-nfc-card`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            employeeId: this.selectedEmployee.id,
            serialNumber,
          }),
        });

        const result = await response.json();
        if (!response.ok) {
          throw new Error(result.error || 'Fehler beim Senden der Daten');
        }

        this.showMessage(result.message);

        // Nach 3 Sekunden zur Startseite weiterleiten
        setTimeout(() => {
          this.$router.push('/');
        }, 3000);
      } catch (err) {
        this.showMessage(`Fehler: ${err.message}`);
      }
    },
    showMessage(message) {
      this.notificationMessage = message;
      this.nfcStatus = null; // NFC-Status zurücksetzen
    },
  },
  mounted() {
    this.fetchEmployees();
    if (!('NDEFReader' in window)) {
      this.nfcAvailable = false;
    }
  },
};
</script>

<style scoped>
.manual-checkin {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.loading,
.error {
  font-size: 1.2rem;
  color: #333;
  margin: 1rem;
}

.employee-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.employee-tile {
  background-color: #007bff;
  color: white;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  width: calc(45% - 1rem);
}

.employee-tile:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.action-tile {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 2rem;
  text-align: center;
}

.nfc-status {
  font-size: 1rem;
  color: #666;
  margin-top: 1rem;
}

.notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #007bff;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: #333;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000; /* Über anderen Elementen positionieren */
}

.close-button:hover {
  color: #007bff; /* Optionale Hover-Farbe */
  transform: scale(1.1); /* Optionale Vergrößerung bei Hover */
}

</style>
